<template>
    <div class="activeNumber">
        <span class="number">{{numberShow}}</span>
    </div>
</template>

<script>
export default {
    name:'activeNumber',
    props: ['number'],
    data() {
        return {
            numberShow: '0'
        }
    },
    methods: {
        doActiveNumber(n){
            let p = parseInt(this.numberShow);
            n = parseInt(n);
            if(n > p){
                let d = Math.ceil((n-p)/50);    
                let siv = setInterval(() => {
                    p += d;
                    if(p >= n){
                        p = n;
                        this.numberShow = this.$u.toThousand(p);
                        clearInterval(siv);
                    }else{
                        this.numberShow = this.$u.toThousand(p);
                    }
                }, 15)
            }else if(n < p){
                let d = Math.ceil((p-n)/50);
                let siv = setInterval(() => {
                    p -= d;
                    if(p <= n){
                        p = n;
                        this.numberShow = this.$u.toThousand(p);
                        clearInterval(siv);
                    }else{
                        this.numberShow = this.$u.toThousand(p);
                    }
                }, 15)
            }
            
        }
    },
    mounted() {
        this.doActiveNumber(this.number);
        return false;
    },
    watch:{
        number(n){
            this.doActiveNumber(n);
        }
    }
}
</script>

<style lang="less" scoped>
.activeNumber {
    display: inline-block;
}
</style>
